.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12rem;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transform: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* Form */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

/* Media queries - Medium */
@media screen and (max-width:1024px){
    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* Media queries - small */
@media screen and (max-width:600px){
    .container.contact__container{
        width: var(--container-width-sm)
    }
}

/* Modal */

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    
    background-color:#2c2c6c;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
  }
  
  .modal.success {
    background-color: #bded69;
    color: #2c2c6c;
  }
  
  .modal.error {
    background-color: #FFBABA;
    color: #2c2c6c;
  }
  
  .modal.hidden {
    opacity: 0;
    visibility: hidden;
  }